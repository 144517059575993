body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #58bebf;
}

.themeFontColor {
  color: #58bebf;
}

h1 {
  margin: 0px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  padding-bottom: 12px;
  margin-bottom: 19px;
  border-bottom: 1px solid #eeeeee;
}

h3 {
  margin: 0px;
  padding-left: 10px;
  border-left: 2px solid #58bebf;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

h4 {
  margin: 0px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.ant-select {
  width: 300px;
}

.ant-radio-button-wrapper {
  border-start-start-radius: 0 !important;
  border-end-start-radius: 0 !important;
  border-start-end-radius: 0 !important;
  border-end-end-radius: 0 !important;
}

.pageItem {
  padding: 18px 20px 24px;
  background-color: white;
}

.pageItem_block {
  margin-top: 20px;
  display: flex;
}

.pageItem_block_column {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.blockItem {
  margin-top: 20px;
  padding: 18px 20px 24px;
  background-color: white;
}

.pageItem_block > span:nth-child(2) {
  flex: 1;
}

.flexBlockCenter {
  display: flex;
  align-items: center;
}

.flexBlockCenter > .ant-input-affix-wrapper {
  width: 300px;
}

.flexBlockTop {
  display: flex;
  align-items: flex-start;
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
}

.textStyle {
  font-size: 14px;
  line-height: 22px;
}

.titleOneLEtter {
  letter-spacing: 9px;
}

.textAshColor {
  color: #666666;
}

.textBlueColor {
  color: #4e79ee;
}

.medalBefore::before {
  margin-right: 5px;
  margin-bottom: 2px;
  display: inline-block;
  content: '';
  width: 6px;
  height: 6px;
  background: url('./images/medal.png') no-repeat;
  background-size: contain;
}
