.NewConsultationTemplate .ant-card {
    border-radius: 15px;
    overflow: hidden;
}

.NewConsultationTemplate .ant-card-body {
  padding: 8px 10px 14px;
}

.NewConsultationTemplate .cardTitle {
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.NewConsultationTemplate .cardSubTitle {
  margin-left: 2px;
  padding: 2px 7px;
  background-color: #f5f5f5;
  border-radius: 2px;
  font-weight: 800;
  font-size: 10px;
  color: #a1a3b0;
  line-height: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.NewConsultationTemplate .cardInfo {
  margin: 3px 0px 8px;
  width: 146px;
  font-size: 14px;
  color: #9293a0;
  line-height: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.NewConsultationTemplate .cardDescribe {
  width: 146px;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
}

.NewConsultationTemplate .cardDescribe > span {
  padding: 2px 6px;
  border: 1px solid #faeee4;
  font-size: 12px;
  color: #ed964e;
  line-height: 14px;
}
