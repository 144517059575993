.consultation_record {
    padding: 20px;
    width: 100%;
    background-color: white;
}

.consultation_header {
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.consultation_header h4 {
    margin-top: 20px;
}

.select_timer {
    display: flex;
    align-items: center;
}

.select_timer_item {
    margin: 0 20px;
}

.select_timer .ant-radio-group {
    margin-right: 5px;
} 

.select_timer .select_timer_item span {
    padding: 6px 10px;
    display: inline-block;
    border-top: 1px solid salmon;
    border-bottom: 1px solid salmon;
    border-left: 1px solid salmon;
}