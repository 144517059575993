.login {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login .content {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin: auto;
  width: 360px;
}

.login .content p {
  width: 360px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 28px;
  line-height: 33px;
}

.login .content .autoLogin {
  margin: 20px 0 15px;
}

.login .content .ant-input-affix-wrapper {
    height: 55px;
}

.login .user {
  margin-bottom: 15px;
}

.login .loginBtn {
  width: 100%;
  height: 55px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
}
