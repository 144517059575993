.menuItem {
  padding-left: 20px;
  height: 50px;
  line-height: 50px;
}

.ant-layout {
  min-height: 100vh;
  background-color: transparent;
}

.ant-layout-sider {
  width: 18%;
  background: transparent;
}

.ant-layout-content {
  padding: 20px;
  background-color: #f0f2f5;
}

.home .title {
    margin-left: 10px;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  line-height: 16px;
}
