.ConsultationDetails .pageItem1_1 div {
    flex: 1;
}

.ConsultationDetails .pageItem2_3 {
    flex-wrap: wrap;
    justify-content: space-between;
}

.ConsultationDetails .pageItem2_3 > div {
    margin-top: 20px;
    width: 50%;
}